<template>
  <div class="px-5" id="page-billing">
    <h5 class="mb-6 ml-2">Kostnader</h5>

    <b-card
      title=""
      class="mb-2"
      hide="footer"
      v-if="!billing_active">
      <b-alert show variant="warning">Fakturering och kostnader är inte aktiverat för denna förening.</b-alert>
      
    </b-card>

    <b-card
      title=""
      class="mb-2"
      hide-footer
      v-if="billing_active"
    >

        <v-tabs
          background-color="white"
          color="accent-4"
          left
        >
            <v-tab>Finansiell information</v-tab>
            <v-tab>SPAR</v-tab>
            <v-tab>SMS</v-tab>
            <v-tab>E-post</v-tab>
            <v-tab>Budget</v-tab>
            <v-tab>Prisinställning för underföreningar</v-tab>
            <v-tab>Historik</v-tab>
            <v-tab v-if="isTHS">Administration</v-tab>

            <v-tab-item>
              <BillingInvoiceTable
                :billing="billing"
                @toastr='toastr'
              />
            </v-tab-item>

            <v-tab-item>
              <BillingSPARTable
                :billing="billing.spar_pricing"
              />
            </v-tab-item>

            <v-tab-item>
              <BillingSMSTable
                :billing="billing"
              />
            </v-tab-item>

             <v-tab-item>
              <BillingEmailTable
                :billing="billing.email_pricing"
              />
            </v-tab-item>

            <v-tab-item>
              <form>
                <b-alert class="mt-8" show >Ställ in budgeten för SMS och SPAR. Ifall det inte finns någon budget kommer inte SMS eller SPAR att användas.</b-alert>

                <b-form-group id="sms_budget-group" label="SMS Budget" label-for="sms_budget">
                  <b-form-input
                    id="sms_budget"
                    v-model="form.sms_budget"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="spar_budget-group" label="SPAR Budget" label-for="spar_budget">
                  <b-form-input
                    id="spar_budget"
                    v-model="form.spar_budget"
                  ></b-form-input>
                </b-form-group>

                <div class="d-flex justify-content-end">
                  <b-button type="button" variant="primary" @click="savePrice">Spara</b-button>
                </div>
              </form>
            </v-tab-item>

            <v-tab-item>
              <BillingSubcompanyTable
                :billing="billing"
              />
            </v-tab-item>

            <v-tab-item>
              <BillingHistoryTable
                :billing="billing"
              />
            </v-tab-item>

            <v-tab-item v-if="isTHS">
              <b-card class="mt-8">
                <b-form @submit.prevent="saveAdminData($event)">


                   <b-form-group id="input-group-system_cost" label="Systemkostnad per år" label-for="input-system_cost">
                    <b-form-input id="input-system_cost" v-model="form.system_cost" type="text"></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-sms_sek_per_send" label="SMS SEK per skickat" label-for="input-sms_sek_per_send">
                    <b-form-input id="input-sms_sek_per_send" v-model="form.sms_sek_per_send" type="text"></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-swish_pct" label="Swish % per transaktion" label-for="input-swish_pct">
                    <b-form-input id="input-swish_pct" v-model="form.swish_pct" type="text"></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-swish_cost" label="Swish SEK per transaktion" label-for="input-swish_cost">
                    <b-form-input id="input-swish_cost" v-model="form.swish_cost" type="text"></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-cc_pct" label="Kreditkort % per transaktion" label-for="input-cc_pct">
                    <b-form-input id="input-cc_pct" v-model="form.cc_pct" type="text"></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-cc_cost" label="Kreditkort SEK per transaktion" label-for="input-cc_cost">
                    <b-form-input id="input-cc_cost" v-model="form.cc_cost" type="text"></b-form-input>
                  </b-form-group>

                  <b-button type="submit" variant="primary">{{ $t('COMMON.SAVE') }}</b-button>
                </b-form>

              </b-card>
            </v-tab-item>

        </v-tabs>
    </b-card>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

.alert.alert-info {
  color: #3F4254;
  background-color: #F3F6F9;
  border-color: #EBEDF3;
  font-weight: 600;
  font-size: 1rem;
  border-bottom-width: 1px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BillingInvoiceTable from '@/view/pages/ml/billing/BillingInvoiceTable.vue';
import BillingSPARTable from '@/view/pages/ml/billing/BillingSPARTable.vue';
import BillingSMSTable from '@/view/pages/ml/billing/BillingSMSTable.vue';
import BillingSubcompanyTable from '@/view/pages/ml/billing/BillingSubcompanyTable.vue';
import BillingEmailTable from '@/view/pages/ml/billing/BillingEmailTable.vue';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

/** TODO-TRANSLATE **/

export default {
  name: 'billing',
  components: {
    BillingInvoiceTable,
    BillingSMSTable,
    BillingSPARTable,
    BillingSubcompanyTable,
    BillingEmailTable
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'isTHS'])
  },
  mounted() {
    this.loadBilling();
    this.loadCompany();
  },
  data() {
    return {
      disabled: false,
      billing: { use: false, recv: '' },
      form: {
        sms_budget: 0,
        spar_budget: 0,
        system_cost: 0,
        swish_cost: 0,
        swish_pct: 0,
        cc_cost: 0,
        cc_pct: 0,
        sms_sek_per_send: 0,
      },
      billing_active: false,
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadCompany();
      }
    }
  },
  methods: {

    
    str_percent_to_float(str) {
      const f = parseFloat(str+'');

      if (isNaN(f)) {
        return 0;
      }

      return f / 100.0;
    },

    float_percent_to_string(f) {

      if (isNaN(f)) {
        return f;
      }

      const str = f * 100.0;

      return str;
    },


    fixFloat(str) {
      const s = str + '';

      if (s === '') { return 0.0; }
      const r = parseFloat(s);

      if (isNaN(r)) {
        return 0.0;
      }

      return r;
    },
    fixIntWithDefault(str, val) {
      const s = str + '';

      if (str === '') { return val; }

      const r = parseInt(s);

      if (isNaN(r)) {
        return val;
      }

      return r;
    },
    
    saveAdminData(e) {
      e.preventDefault();

      const copy = JSON.parse(JSON.stringify(this.form));

      copy.system_cost = this.fixFloat(copy.system_cost);
      copy.sms_sek_per_send = this.fixFloat(copy.sms_sek_per_send);
      copy.swish_cost = this.fixFloat(copy.swish_cost);
      copy.cc_cost = this.fixFloat(copy.cc_cost);

      copy.swish_pct = this.str_percent_to_float(copy.swish_pct+'');
      copy.cc_pct = this.str_percent_to_float(copy.cc_pct+'');

      axios
        .put(`/company/${this.currentCompanyId}`, { company_id: this.currentCompanyId, billing: copy })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), 'Föreningsinställningar uppdaterade');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte spara föreningsdata');
        });

      this.form.company_id = this.currentCompanyId;
      
      axios
        .put(`/billing/${this.form.billing_id}`, copy )
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), 'Kostnader uppdaterade');
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera kostnader');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte spara kostnader');
        });
    },
    loadCompany() {
      axios
        .get(`/company?company_id=${this.currentCompanyId}`)
        .then(res => {
          // this.billing = res.data.company.billing;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta föreningsdata');
        });
    },
    loadBilling() {

      axios
        .get(`/billing/active/${this.currentCompanyId}`)
        .then(res => {
          if (res.data === null || res.data === undefined || res.data.billing_id === undefined) {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Fakturering är ej påslaget för denna förening');
            this.billing_active = false;
            return;
          }

          this.billing_active = true;
          this.billing = res.data;
          
          this.billing.swish_pct = this.float_percent_to_string(this.billing.swish_pct+'');
          this.billing.cc_pct = this.float_percent_to_string(this.billing.cc_pct+'');

          this.form = this.billing;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta kostnader');
        });
    },
    savePrice() {
      if (this.billing && this.billing.billing_id) {
        const billing_id = this.billing.billing_id;
        const spar_budget  = this.form.spar_budget;
        const sms_budget = this.form.sms_budget;


        axios
          .put(`/billing/${billing_id}`, { billing_id, spar_budget, sms_budget })
          .then(res => {
            if (res.status === 204) {
              this.toastr('success', this.$t('COMMON.OK'), 'Priset har sparats.');
            }
          })
          .catch(err => {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Det gick inte att spara priset.');
          });
      }
    },
    
  }
};
</script>
